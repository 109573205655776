export const accounts = [
  {
    email: "easternchemcorp1@yahoo.in",
    password: "Akhil123",
    name: "Akhil",
  },
  {
    email: "mayuresh@bayleafhrsolutions.com",
    password: "Mayuresh123",
    name: "Mayuresh",
  },
  {
    email: "rushabh@crescofinserve.com",
    password: "Rushabh123",
    name: "Rushabh",
  },
  {
    email: "gagan.mishra@live.in",
    password: "Gagan123",
    name: "Gagan",
  },
  {
    email: "Arvind@even.in",
    password: "Arvind123",
    name: "Arvind",
  },
  {
    email: "rahul@insurmonthly.com",
    password: "Rahul123",
    name: "Rahul",
  },
  {
    email: "vinay@healthysure.in",
    password: "Vinay123",
    name: "Vinay",
  },
  {
    email: "Vinitatole3090@gmail.com",
    password: "Vinit123",
    name: "Vinit",
  },
  {
    email: "amarpreet@truetab.ai",
    password: "Amarpreet123",
    name: "Amarpreet",
  },
  {
    email: "bachatkar@gmail.com",
    password: "Ashish123",
    name: "Ashish",
  },
  {
    email: "gauravagarwal.oic@gmail.com",
    password: "Gaurav123",
    name: "Gaurav",
  },
  {
    email: "hallmark.finserv@gmail.com",
    password: "Swapnil123",
    name: "Swapnil",
  },
  {
    email: "sachin@goqii.com",
    password: "Sachin123",
    name: "Sachin",
  },
  {
    email: "sales@healthysure.in",
    password: "Sales123",
    name: "Sales",
  },
  {
    email: "BimaKavach",
    password: "BimaKavach123",
    name: "BimaKavach",
  },
  {
    email: "placements@healthysure.in",
    password: "Placements123",
    name: "Placements",
  },
  {
    email: "yash.sangharajka@gmail.com",
    password: "Yash123",
    name: "Yash",
  },
  {
    email: "hemanth@tartanhq.com",
    password: "Hemanth123",
    name: "Hemanth",
  },
  {
    email: "Paytm",
    password: "Paytm123",
    name: "Paytm",
  },
  {
    email: "manoj.g@kredily.com",
    password: "Manoj123",
    name: "Manoj",
  }
]
